/**
 *   This is a service for a specific store module which uses the api.ts Axios instance as a base.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

 import Api from '@/services/api';
 import AuthService from '@/services/auth';
 import Store from '@/store/index';

 export default {

  resetStore() {
    // reset store to empty
    Store.dispatch('guards/setGuards', {
      guards: [],
    });
  },

  async listAll(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['guards/guards'].length < 1) {
      try {
        const response = await Api().get('/guard/list_all');

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            this.resetStore();
            return response.data.message;
          } else {
            Store.dispatch('guards/setGuards', {
              guards: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        this.resetStore();
        if (error.response) {
          return error.response.statusText;
        } else {
          await AuthService.logSupportDebug('guards.ts - listAll - 45 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },

  listEstatesLinkedToGuard(config: {params: { guardId: number }}) {
    return Api().get('/guard/list_estates_linked_to_guard', config);
  },

  create(params: {
    securityId: string,
    password: string,
    idNumber: string,
    firstName: string,
    lastName: string,
    contactNumber: string,
    estateIds: string,
  }) {
    return Api().post('/guard/create', params);
  },

  update(params: {
    id: number;
    securityId: string,
    idNumber: string,
    firstName: string,
    lastName: string,
    contactNumber: string,
    estateIds: string,
    suspended: boolean,
    password: string,
  }) {
    return Api().post('/guard/update', params);
  },

  suspendActivate(params: {
    id: number;
    suspended: boolean,
  }) {
    return Api().post('/guard/suspend_activate', params);
  },

  delete(params: { id: number }) {
    return Api().post('/guard/delete', params);
  },
};
