


















































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import MaterialCard from '@/components/MaterialCard.vue';
import ConfirmActionDialog from '@/components/ConfirmActionDialog.vue';

// Models
import { Guard } from '@/models/Guard.model';
import { Estate } from '@/models/Estate.model';

// --- Services ---
import AppService from '@/services/app';
import AuthService from '@/services/auth';
import GuardService from '@/services/guard';
import EstateService from '@/services/estate';

// --- Third Party imports ---
import crypto from 'crypto-js';


@Component({
  components: {
    MaterialCard,
    ConfirmActionDialog,    
  },
})

export default class Guards extends Vue {

  // used by Filter Area
  search: string = '';

  // used by No Data Slot
  isLoading: boolean = true;

  // used by Item Dialog for Create/Update
  showItemDialog: boolean = false;
  title: string = '';
  clickedItem: Guard = {
    id: 0,
    securityId:  '',
    password: '',
    idNumber: '',
    firstName: '',
    lastName: '',
    contactNumber: '',
    createdAt: '',
    createdBy:  0,
    lastLoginAt: '',
    updatedAt: '',
    updatedBy: '',
    suspended: false,
    suspendedBy: 0,
    deletedAt: '',
    deletedBy: 0,
    estates: [],
  };

  // used by Confirm Action Dialog for Delete Item
  action: string = '';
  dialogContent: string = '';
  confirmActionDelay: boolean = false;
  showConfirmActionDialog: boolean = false;

  isView: boolean = false;
  isUpdate: boolean = false;
  valid: boolean = false;

  rules = {
    required: (value: string) => !!value || '',
    idNumber: (value: string) => /^(?=.*[a-zA-Z0-9- ])[a-zA-Z0-9- ]+$/.test(value) || 'Invalid character',
    cellNumber: (value: string) => /^[0-9]{10}$/.test(value) || 'Please enter a valid Phone Number',
    // mustHaveEstates: (value: string) => this.mustHaveEstates() || 'Please select at least one Estate',
  };

  clicked: boolean = false;

  // #region Functions to assist validation rules
    // mustHaveEstates() {
    //   // needs at least personality (pre-selected) & 1 of the others, so minimum selected must be 1
    //   return this.clickedItem.estates && this.clickedItem.estates.length >= 1;
    // }
  // #endregions

  // region Functions to display list data on page load
    headers() {
      return [
        {
          text: 'Security ID',
          value: 'securityId',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Contact Number',
          value: 'contactNumber',
        },
        {
          text: 'Last Login',
          value: 'lastLogin',
        },
        {
          text: 'Created At',
          value: 'createdAt',
        },
        {
          sortable: false,
          text: '',
          value: 'action',
          align: 'end',
        },
      ];
    }

    async beforeMount() {
      await EstateService.listAll(false);
      await this.listAll();

    }

    async listAll() {
      try {
        this.isLoading = true;
        const response: string = await GuardService.listAll(true);

        if (response.length > 0 && response !== 'No Data Found') {
          AppService.errorHandler(response);
        }

      } finally {
        this.isLoading = false;
      }
    }

  // #endregion

  // #region Functions required for the Item Dialog for Create/Update/View
    async launchNewItemDialog() {
      if (this.$refs.itemForm) {
        (this.$refs.itemForm as any).resetValidation();
      }

      this.clickedItem = {
        id: 0,
        securityId:  '',
        password: '',
        idNumber: '',
        firstName: '',
        lastName: '',
        contactNumber: '',
        createdAt: '',
        createdBy:  0,
        lastLoginAt: '',
        updatedAt: '',
        updatedBy: '',
        suspended: false,
        suspendedBy: 0,
        deletedAt: '',
        deletedBy: 0,
        estates: [],
      };

      this.valid = false;
      this.title = 'Create New Guard';
      this.isView = false;
      this.isUpdate = false;
      this.showItemDialog = true;
    }

    async launchViewItemDialog(item: Guard) {
      this.clickedItem = Object.assign({}, item);

      await this.getEstatesLinkedToGuard();

      this.isView = true;
      this.isUpdate = false;
      this.valid = false;
      this.title = this.clickedItem.firstName + ' ' + this.clickedItem.lastName;
      this.showItemDialog = true;
    }

    async launchUpdateItemDialog(item: Guard) {
      this.clickedItem = Object.assign({}, item);

      await this.getEstatesLinkedToGuard();

      if (this.$refs.itemForm) {
        (this.$refs.itemForm as any).resetValidation();
      }
      this.isView = false;
      this.isUpdate = true;
      this.title = 'Update ' + this.clickedItem.firstName + ' ' + this.clickedItem.lastName;
      this.showItemDialog = true;
    }

    doCreateUpdate() {
      if (this.clickedItem.id <= 0) {
        this.doCreate();
      } else {
        this.doUpdate();
      }
    }

    async doCreate() {
      try {
        this.clicked = true;

        const response = await GuardService.create({
          securityId:  this.clickedItem.securityId,
          password: crypto.SHA256(this.clickedItem.password).toString().toUpperCase(),
          idNumber: this.clickedItem.idNumber,
          firstName: this.clickedItem.firstName,
          lastName: this.clickedItem.lastName,
          contactNumber: this.clickedItem.contactNumber,
          estateIds: this.clickedItem.estates!.map((item: Estate) => item.id).join(','),
        });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listAll();
            this.showItemDialog = false;
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          await AuthService.logSupportDebug('Estates.vue - doCreate - 397 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.clicked = false;
      }
    }

    async doUpdate() {
      try {
        console.log(`Guards.vue - 646 - welcome to doUpdate`);
        this.clicked = true;
        this.confirmActionDelay = true;

        const response = await GuardService.update({
          id: this.clickedItem.id,
          securityId:  this.clickedItem.securityId,
          idNumber: this.clickedItem.idNumber,
          firstName: this.clickedItem.firstName,
          lastName: this.clickedItem.lastName,
          contactNumber: this.clickedItem.contactNumber,
          estateIds: this.clickedItem.estates!.map((item: Estate) => item.id).join(','),
          suspended: this.clickedItem.suspended,
          password: crypto.SHA256(this.clickedItem.password).toString().toUpperCase(),
        });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listAll();
            this.showItemDialog = false;
            this.showConfirmActionDialog = false;
          }
        } else {
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        console.log(`Guards.vue - 675 - error`, error);

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          await AuthService.logSupportDebug('Guard.vue - doUpdate - 677 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.clicked = false;
        this.confirmActionDelay = false;
      }
    }

    async getEstatesLinkedToGuard() {
      try {
        this.clickedItem.estates = [];

        const response = await GuardService.listEstatesLinkedToGuard({params: { guardId: this.clickedItem.id}});
        
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false' || response.data.length < 1) {
            AppService.logSupportDebug('Guards.vue - getEstatesLinkedToGuard - 611 - ' + response.data.message);
            AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
          } else {
            this.clickedItem.estates = response.data;
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response);
        } else {
          AppService.logSupportDebug('EquipmentTypes.vue - getShootTypesByEquipmentType - 692 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      }
    }

  // #endregion

  // #region Functions required for the Confirm Action Dialog for Finalize/Delete
    launchActivateItemDialog(item: Guard) {
      this.clickedItem = item;

      this.action = 'ACTIVATE';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #71BC78">re-activate</span> the Guard named <span style="font-weight: 700">' 
        + item.firstName + ' ' + item.lastName + '</span>.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    launchSuspendItemDialog(item: Guard) {
      this.clickedItem = item;

      this.action = 'SUSPEND';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #C01212 ">suspend</span> the Guard named <span style="font-weight: 700">' 
        + item.firstName + ' ' + item.lastName + '</span>.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    launchDeleteItemDialog(item: Guard) {
      this.clickedItem = item;

      this.action = 'DELETE';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #C01212">delete</span><br/>'
        + '<span style="font-weight: 700">' + item.firstName + ' ' + item.lastName + ': ' + item.securityId + '</span>'
        + '.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    ConfirmActionDialog_cancel() {
      this.showConfirmActionDialog = false;
    }

    async ConfirmActionDialog_doConfirmed() {
      if (this.action === 'SUSPEND') {
        this.clickedItem.suspended = true;
        await this.doSuspendActivate();

      } else if (this.action === 'ACTIVATE') {
        this.clickedItem.suspended = false;
        await this.doSuspendActivate();

      } else if (this.action === 'DELETE') {
        await this.doDelete();
      }
    }

    async doSuspendActivate() {
      try {
        this.confirmActionDelay = true;
        const response = await GuardService.suspendActivate({
          id: this.clickedItem.id,
          suspended: this.clickedItem.suspended,
        });

        if (response && response.data && response.data.result && response.data.result === 'false') {
          AppService.errorHandler(response.data.message);
        } else {
          AppService.successHandler(response.data.message);
          await this.listAll();
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
        this.showConfirmActionDialog = false;
      }
    }

    async doDelete() {
      try {
        this.confirmActionDelay = true;
        const response = await GuardService.delete({id: this.clickedItem.id});

        if (response && response.data && response.data.result && response.data.result === 'false') {
          AppService.errorHandler(response.data.message);
        } else {
          AppService.successHandler(response.data.message);
          await this.listAll();
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.confirmActionDelay = false;
        this.showConfirmActionDialog = false;
      }
    }
  // #endregion

}
